<template>
  <el-card id="callMission">
    <header>
      <h2 style="margin-bottom:10px">呼叫任务</h2>
    </header>
    <main>
      <el-tabs v-model="activeName">
        <el-tab-pane label="已分配号码任务" name="yet">
          <yetCall />
        </el-tab-pane>
        <el-tab-pane label="未分配号码任务" name="not">
          <notCall />
        </el-tab-pane>
      </el-tabs>
    </main>
  </el-card>
</template>

<script>
import yetCall from "@/views/callMission/yetCall.vue";
import notCall from "@/views/callMission/notCall.vue";

export default {
  components: { yetCall, notCall },
  data() {
    return {
      activeName: "yet",
    };
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,.el-button--primary {
    color: #FFF;
    background-color: #3278FE;
    border-color: #3278FE;
    &:hover{
      opacity: 0.7;
    }
}
#callMission {
   height: 100%;
  overflow-y: scroll;
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          width: 20%;
          display: flex;
          align-items: center;
          .el-select {
            width: 100%;
          }
          span {
            margin: 10px;
            width: 70px;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
}
</style>